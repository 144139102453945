'use strict';

Gri.module({
  name: 'page-boxes',
  ieVersion: null,
  $el: $('.page-boxes'),
  container: '.page-boxes',
  fn: function () {
  }
});
